import { createAsyncThunk } from 'store/utils';
import {
  BusinessEntity,
  ClassificationGroupItem,
  STANDARD_CLASSIFICATION_GROUPS,
} from 'dto/classification';
import { setCurrentBusinessEntity } from 'features/common/commonActions';
import qs from 'qs';
import { api } from '@fleet/shared';

export const getClassificationGroups = createAsyncThunk<
  Array<ClassificationGroupItem>
>(
  'getClassificationGroups',
  async () =>
    (
      await api.get<{ items: Array<ClassificationGroupItem> }>(
        `/classifications/groups${qs.stringify(
          {
            ids: Object.values(STANDARD_CLASSIFICATION_GROUPS),
          },
          {
            addQueryPrefix: true,
            skipNulls: true,
            arrayFormat: 'comma',
          }
        )}`,
        {
          baseURL: process.env.REACT_APP_API_BD,
        }
      )
    ).data.items
);

export const getBusinessEntities = createAsyncThunk<Array<BusinessEntity>>(
  'classification/businessEntities',
  async (_, { getState, dispatch }) => {
    const {
      data: { items: businessEntities },
    } = await api.get<{ items: BusinessEntity[] }>('/users/business-entities', {
      baseURL: process.env.REACT_APP_API_OM,
    });

    const defaultBusinessEntity =
      businessEntities.find(({ isMain }) => isMain) || businessEntities[0];
    dispatch(
      setCurrentBusinessEntity(
        getState().common.currentBusinessEntityId || defaultBusinessEntity.id
      )
    );

    return businessEntities;
  }
);

export const getClassifications = createAsyncThunk(
  'getClassifications',
  async (_, { dispatch }) => {
    await Promise.all([dispatch(getClassificationGroups())]);
  }
);
