import { FC, useCallback, useEffect, useMemo } from 'react';
import { InventoryBucketSearchForm } from './InventoryBucketSearchForm';
import {
  ConfirmDeleteModal,
  Icon,
  SearchResult,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowActive,
} from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import {
  inventoryBucketFilterSelector,
  inventoryBucketsSelector,
} from 'features/inventoryBucket/InventoryBucketSelectors';
import { inventoryBucketLoadingSelector } from 'features/loading/loadingSelectors';
import {
  Button,
  CardHeader,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { InventoryBucket } from 'dto/inventory';
import { TransTableHead } from 'i18n/trans/table';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import {
  deleteInventoryBucket,
  getInventoryBuckets,
} from 'features/inventoryBucket/inventoryBucketActions';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { TransModal } from 'i18n/trans/modal';

const IS_DELETE_FUNCTIONALITY_HIDDEN = true; //This functionality isn't fully implemented in the BE yet

interface InventoryBucketTableProps {}

export const InventoryBucketTable: FC<InventoryBucketTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const inventoryBuckets = useSelector(inventoryBucketsSelector);
  const loading = useSelector(inventoryBucketLoadingSelector);
  const filter = useSelector(inventoryBucketFilterSelector);
  const { open: isOpen, onOpen, onClose } = useModal();

  const data = useMemo(
    () => inventoryBuckets?.items ?? [],
    [inventoryBuckets?.items]
  );

  const link = useCallback(
    (row: Row<InventoryBucket>) =>
      `/configuration/bucket/edit/${row.original.id}`,
    []
  );

  const columns: TableColumns<InventoryBucket> = useMemo(
    () => [
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
        Cell: ({ row, value }) => (
          <MuiLink underline="none" component={Link} to={link(row)}>
            {value}
          </MuiLink>
        ),
      },
      {
        accessor: 'inventoryClass',
        Header: <TransTableHead i18nKey="inventoryClass" />,
        Cell: ({ value }) => value.name,
      },
      {
        id: 'owner.id',
        accessor: ({ owner }) => owner?.name,
        Header: <TransTableHead i18nKey="owner" />,
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
      },
    ],
    [link]
  );

  const getRowId = useCallback((row) => `${row.id!}`, []);

  const getPage = useCallback(
    (pageSize: number) => {
      if (inventoryBuckets) {
        const { limit = pageSize, offset } = inventoryBuckets;
        return offset / limit;
      }
      return 0;
    },
    [inventoryBuckets]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      dispatch(getInventoryBuckets({ ...filter, ...paginationParams }));
    },
    [filter, dispatch]
  );

  const table = useTable<InventoryBucket>(
    {
      data,
      columns,
      getRowId,
      pageCount: -1,
      total: inventoryBuckets?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const {
    activeFlatRow,
    resetRowActive,
    state: { selectedRowIds },
  } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.replace(link(activeFlatRow));
    }
  }, [activeFlatRow, history, link]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, resetRowActive]);

  const removeSelectedRows = useCallback(async () => {
    await Promise.all(
      Object.keys(selectedRowIds).map((id) =>
        dispatch(deleteInventoryBucket(id))
      )
    );

    onClose();
    dispatch(getInventoryBuckets(filter));
  }, [selectedRowIds, dispatch, filter, onClose]);

  return (
    <>
      <InventoryBucketSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <>
              <CardHeader
                subheader={
                  <Stack direction="row" alignItems="baseline">
                    <Typography
                      variant="subtitle"
                      fontWeight="700"
                      color="common.black"
                    >
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <TransSubtitle
                        i18nKey="inventoryBucketQty"
                        values={{ num: data?.length }}
                      />
                    </Typography>
                  </Stack>
                }
                action={
                  IS_DELETE_FUNCTIONALITY_HIDDEN ? null : (
                    <>
                      <Button
                        hidden={true}
                        startIcon={<Icon name="trash" />}
                        sx={{ p: 0, whitespace: 'nowrap' }}
                        color="error"
                        onClick={onOpen}
                        disabled={
                          !Object.keys(table.state.selectedRowIds).length
                        }
                      >
                        <TransButton i18nKey="deleteSelected" />
                      </Button>
                      <ConfirmDeleteModal
                        handleDelete={removeSelectedRows}
                        title={<TransModal i18nKey="deleteInventoryBuckets" />}
                        description={
                          <TransModal i18nKey="inventoryBucketsDeletionDescription" />
                        }
                        isOpen={isOpen}
                        onClose={onClose}
                      />
                    </>
                  )
                }
              />
            </>
          }
          table={table}
        />
      </SearchResult>
    </>
  );
};
