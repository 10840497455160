import { Classifier } from '@fleet/shared/dto/classifier';

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  BUCKET_NESTING_TYPE = 'BUCKET_NESTING_TYPE',
  INVENTORY_CONTROL_LEVEL = 'INVENTORY_CONTROL_LEVEL',

  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.BUCKET_NESTING_TYPE,
  ClassificationGroup.INVENTORY_CONTROL_LEVEL,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;

export interface BusinessEntity extends Classifier<number> {
  isMain: boolean;
  contactType: string;
}
