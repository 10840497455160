import { AddButton, CardHeader, Layout, Loadable } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { TransNav } from 'i18n/trans/nav';
import { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { InventoryBucketDetails } from './InventoryBucketDetails';
import { InventoryBucketTable } from './InventoryBucketTable';
import { useSelector } from 'store/utils';
import { inventoryBucketLoadingSelector } from 'features/loading/loadingSelectors';

interface InventoryBucketProps {}

export const InventoryBucket: FC<InventoryBucketProps> = () => {
  const history = useHistory();
  const loading = useSelector(inventoryBucketLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransNav i18nKey="inventoryBucket" />}>
            <AddButton
              onClick={() => history.push('/configuration/bucket/create')}
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/configuration/bucket/:action(create|edit)/:id?"
              component={InventoryBucketDetails}
              exact
            />
          </Switch>
        }
      >
        <InventoryBucketTable />
      </Layout>
    </Loadable>
  );
};
