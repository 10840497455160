import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  SearchForm,
  SelectField,
  TextField,
  useForm,
  makeClassificationOptions,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { InventoryBucketFilter, InventoryBucketValues } from 'dto/inventory';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { fetchInventoryClasses } from 'features/common/commonService';
import {
  getInventoryBuckets,
  setInventoryBucketFilter,
} from 'features/inventoryBucket/inventoryBucketActions';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { inventoryBucketFilterSelector } from 'features/inventoryBucket/InventoryBucketSelectors';

interface InventoryBucketSearchFormProps {}

export const InventoryBucketSearchForm: FC<InventoryBucketSearchFormProps> =
  () => {
    const dispatch = useDispatch();
    const currentBusinessEntityId = useSelector(
      currentBusinessEntityIdSelector
    );
    const [inventoryClassOptions, setInventoryClassOptions] = useState<
      Array<{ value: string; label: string }>
    >([]);
    const businessEntitiesOptions = useClassificationOptions(
      ClassificationGroup.BUSINESS_ENTITY
    );
    const filter = useSelector(inventoryBucketFilterSelector);

    const onSubmit = useCallback(
      (values: Partial<InventoryBucketFilter>) => {
        formSubmit(async () => {
          dispatch(getInventoryBuckets({ ...values, offset: 0 }));
        });
      },
      [dispatch]
    );

    const initialValues = useMemo<Partial<InventoryBucketValues>>(
      () => ({
        ownerId: currentBusinessEntityId,
        ...filter,
      }),
      [filter, currentBusinessEntityId]
    );

    useEffect(() => {
      if (Object.keys(filter).length === 0) {
        dispatch(setInventoryBucketFilter(initialValues));
      }
    }, [dispatch, filter, initialValues]);

    const { form, handleSubmit, values } = useForm<InventoryBucketFilter>({
      initialValues,
      onSubmit,
      subscription: { values: true },
    });

    const fetchInventoryClassOptions = useCallback(async () => {
      setInventoryClassOptions(
        makeClassificationOptions(await fetchInventoryClasses(values.ownerId))
      );
    }, [values.ownerId]);

    useEffect(() => {
      if (values.ownerId) {
        fetchInventoryClassOptions();
      }
    }, [fetchInventoryClassOptions, values.ownerId]);

    const handleReset = useCallback(() => {
      form.reset();
      dispatch(setInventoryBucketFilter({}));
    }, [dispatch, form]);

    return (
      <SearchForm title={<TransSubtitle i18nKey="search" />}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <Grid container columns={5} spacing={2}>
              <Grid item xs={1}>
                <TextField name="code" label={<TransField i18nKey="code" />} />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="ownerId"
                  label={<TransField i18nKey="owner" />}
                  options={businessEntitiesOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="inventoryClassId"
                  label={<TransField i18nKey="inventoryClass" />}
                  options={inventoryClassOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFilters" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button variant="contained" type="submit" icon="search">
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
