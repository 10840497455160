import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header } from 'components/Header';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import { AppVersion, EnvironmentBanner } from '@fleet/shared';
import { appLoadingSelector } from 'features/loading/loadingSelectors';
import { InventoryConfiguration } from 'routes/InventoryConfiguration/InventoryConfiguration';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import AuthWrapper from 'wrappers/AuthWrapper';

export default function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const loading = useSelector(appLoadingSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getBusinessEntities());
    }
  }, [auth.isAuthenticated, dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated && currentBusinessEntityId) {
      dispatch(getClassifications());
    }
  }, [auth.isAuthenticated, dispatch, currentBusinessEntityId]);

  return (
    <>
      <Router>
        <AuthWrapper appLoading={loading}>
          <EnvironmentBanner envName={process.env.REACT_APP_ENVIRONMENT!} />
          <Header />
          <Switch>
            <Route
              exact
              path="/configuration/:subPath?/:action(create|edit)?/:id?"
              component={InventoryConfiguration}
            />
          </Switch>
        </AuthWrapper>
      </Router>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
}
