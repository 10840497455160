import {
  Checkbox,
  ConfirmDeleteModal,
  Icon,
  SearchResult,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowActive,
} from '@fleet/shared';
import {
  Button,
  CardHeader,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import {
  Row,
  useFilters,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { InventoryModel } from 'dto/inventory';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { Link, useHistory, useParams } from 'react-router-dom';
import { formatDate } from '@fleet/shared/utils/date';
import { InventoryModelSearchForm } from 'routes/InventoryConfiguration/InventoryModel/InventoryModelSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import { inventoryModelLoadingSelector } from 'features/loading/loadingSelectors';
import {
  inventoryModelFilterSelector,
  inventoryModelsSelector,
} from 'features/inventoryModel/inventoryModelSelector';
import {
  deleteInventoryModel,
  getInventoryModels,
} from 'features/inventoryModel/inventoryModelActions';
import { TransField } from 'i18n/trans/field';
import { TransModal } from 'i18n/trans/modal';

const IS_DELETE_FUNCTIONALITY_HIDDEN = true; //This functionality is't fully implemented in the BE yet

export const InventoryModelTable: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(inventoryModelLoadingSelector);
  const models = useSelector(inventoryModelsSelector);
  const filter = useSelector(inventoryModelFilterSelector);
  const { open: isOpen, onOpen, onClose } = useModal();

  const data = useMemo(() => models?.items ?? [], [models?.items]);

  const link = useCallback(
    (row: Row<InventoryModel>) =>
      `/configuration/model/edit/${row.original.id}`,
    []
  );

  const columns: TableColumns<InventoryModel> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="modelName" />,
        Cell: ({ row, value }) => (
          <MuiLink underline="none" component={Link} to={link(row)}>
            {value}
          </MuiLink>
        ),
      },
      {
        id: 'owner.id',
        accessor: ({ owner }) => owner?.name,
        Header: <TransTableHead i18nKey="owner" />,
      },
      {
        id: 'nestingType.id',
        accessor: ({ nestingType }) => nestingType?.name,
        Header: <TransTableHead i18nKey="nestingType" />,
      },
      {
        id: 'controlLevel.id',
        accessor: ({ controlLevel }) => controlLevel?.name,
        Header: <TransTableHead i18nKey="controlLevel" />,
      },
      {
        id: 'inventoryClass.id',
        accessor: ({ inventoryClass }) => inventoryClass?.name,
        Header: <TransTableHead i18nKey="inventoryClass" />,
      },
      {
        id: 'validFrom',
        accessor: ({ validity }) => formatDate(validity.from),
        Header: <TransTableHead i18nKey="validFrom" />,
      },
      {
        id: 'validTo',
        accessor: ({ validity }) => formatDate(validity.to),
        Header: <TransTableHead i18nKey="validTo" />,
      },
      {
        accessor: 'areControlsBidirectional',
        Header: <TransTableHead i18nKey="controlsBidirectional" />,
        Cell: (cell) =>
          cell.value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
      },
      {
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="isActive" />,
        Cell: (cell) =>
          cell.value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
      },
    ],
    [link]
  );

  const getRowId = useCallback((row) => `${row.id!}`, []);

  const getPage = useCallback(
    (pageSize: number) => {
      if (models) {
        const { limit = pageSize, offset } = models;
        return offset / limit;
      }
      return 0;
    },
    [models]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      dispatch(getInventoryModels({ ...filter, ...paginationParams }));
    },
    [filter, dispatch]
  );

  const table = useTable<InventoryModel>(
    {
      data,
      columns,
      getRowId,
      pageCount: -1,
      total: models?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    useFilters,
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const {
    activeFlatRow,
    resetRowActive,
    state: { selectedRowIds },
  } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.replace(link(activeFlatRow));
    }
  }, [activeFlatRow, history, link]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, resetRowActive]);

  const removeSelectedRows = useCallback(async () => {
    await Promise.all(
      Object.keys(selectedRowIds).map((id) =>
        dispatch(deleteInventoryModel(id))
      )
    );
    onClose();
    dispatch(getInventoryModels(filter));
  }, [selectedRowIds, onClose, dispatch, filter]);

  const handleActiveFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isActive', e.target.checked || undefined),
    [table]
  );

  return (
    <>
      <InventoryModelSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <>
              <CardHeader
                subheader={
                  <Stack
                    direction="row"
                    alignItems="baseline"
                    sx={{ gap: '20px' }}
                  >
                    <Typography
                      variant="subtitle"
                      fontWeight="700"
                      color="common.black"
                    >
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <TransSubtitle
                        i18nKey="inventoryModelsQty"
                        values={{ num: data?.length }}
                      />
                    </Typography>
                    <Checkbox
                      size="small"
                      label={
                        <Typography
                          variant="body2"
                          color="text.primary"
                          component="span"
                        >
                          <TransField i18nKey="activeInventoryModels" />
                        </Typography>
                      }
                      onChange={handleActiveFilterToggle}
                      inline
                    ></Checkbox>
                  </Stack>
                }
                action={
                  IS_DELETE_FUNCTIONALITY_HIDDEN ? null : (
                    <>
                      <Button
                        startIcon={<Icon name="trash" />}
                        sx={{ p: 0, whitespace: 'nowrap' }}
                        color="error"
                        onClick={onOpen}
                        disabled={
                          !Object.keys(table.state.selectedRowIds).length
                        }
                      >
                        <TransButton i18nKey="deleteSelected" />
                      </Button>
                      <ConfirmDeleteModal
                        handleDelete={removeSelectedRows}
                        title={<TransModal i18nKey="deleteInventoryModels" />}
                        description={
                          <TransModal i18nKey="inventoryModelsDeletionDescription" />
                        }
                        isOpen={isOpen}
                        onClose={onClose}
                      />
                    </>
                  )
                }
              />
            </>
          }
          table={table}
        />
      </SearchResult>
    </>
  );
};
